<template>
  <div></div>
</template>
<script>
export default {
  name: 'profileLogout',
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {
    this.logout()
  },
  methods: {
    logout() {
      this.$parent.logout()
    }
  }
}
</script>
